/* Base styles */
:root {
  --primary-color: #0877D1;
  --primary-dark: #0656a0;
  --background-color: white;
  --text-color: #0877D1;
  --light-blue: #f5f8ff;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --focus-shadow: rgba(8, 119, 209, 0.2);
  --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--primary-dark));
  --surface-color: #ffffff;
  
  /* New standardized variables */
  --border-radius-sm: 6px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --shadow-default: 0 2px 4px var(--shadow-color);
  --shadow-hover: 0 4px 8px var(--shadow-color);
  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --transition-speed: 0.2s;
  --transition-timing: ease;
  
  /* Breakpoints */
  --mobile: 480px;
  --tablet: 768px;
  --desktop: 1024px;
}

/* Base Styles */
body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  background: var(--background-color);
  min-height: 100vh;
  padding: 0;
  overflow-x: hidden;
}

/* Layout */
.parent {
  display: grid;
  grid-template-columns: 1fr minmax(400px, 2fr) 1fr;  /* Changed first and last columns to 1fr */
  gap: var(--spacing-md);
  padding: 10px;
  color: var(--text-color);
  background: var(--background-color);
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  margin: 0 auto;
}

.mobile-container {
  display: none;
}

/* Column Styles */
.col1, .col2, .col3 {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.col1 {
  padding-top: var(--spacing-lg);
  order: 1;
  align-items: center;  /* Add this */
  justify-content: flex-start; 
}

.col2 {
  gap: 8px;
  order: 2;
}

.col3 {
  order: 3;
  padding-top: var(--spacing-sm);
}


/* Typography */
h1 {
  font-size: 1.5rem;
  margin: 5px 0;
  color: var(--primary-color);
}

/* Score Display */
.score {
  padding: var(--spacing-xs);
  font-weight: bold;
  margin: 0;
  color: var(--primary-color);
  font-size: 1.1rem;
}

/* Board Styles */
.board {
  background: var(--primary-gradient);
  border: 4px solid var(--primary-color);
  border-radius: var(--border-radius-md);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  gap: 8px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
  box-shadow: var(--shadow-default);
}

.board > * {
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
}

.cubie {
  transition: all var(--transition-speed) var(--transition-timing);
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-sm);
  color: var(--primary-color);
  font-weight: bold;
  box-shadow: var(--shadow-default);
}

.cubie:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-hover);
}

.cubie span {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(1rem, 4vw, 2.5rem);
  min-width: 2ch;
}

.board-row {
  display: contents;
  margin-bottom: var(--spacing-xs);
}

/* Form Elements */
textarea {
  width: 75%;
  padding: var(--spacing-sm);
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-lg);
  font-size: 1.1rem;
  resize: vertical;
  background: linear-gradient(145deg, var(--light-blue), #ffffff);
  min-height: 30px;
  transition: all var(--transition-speed) var(--transition-timing);
  margin-bottom: 10px;
  box-shadow: var(--shadow-default);
}

textarea:focus {
  outline: none;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px var(--focus-shadow);
}

/* Button Styles */
.reshuffle-button {
  width: 100%;
  max-width: 300px;
  padding: 12px 24px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all var(--transition-speed) var(--transition-timing);
  margin: var(--spacing-xs) auto;
  display: block;
  box-shadow: var(--shadow-default);
}

.reshuffle-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-hover);
  background: var(--primary-dark);
}

.reshuffle-button:active {
  transform: translateY(0);
}

/* Found Words Display */
.entered-word {
  background: white;
  //border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-lg);
  max-height: 300px;
  overflow-y: auto;
  //box-shadow: var(--shadow-default);
}


/* Word Count Display */
.word-count {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background: white;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-default);
}

/* Feedback Messages */
.answer {
  text-align: center;
  padding: var(--spacing-xs);
  min-height: 20px;
  font-weight: bold;
  margin-bottom: var(--spacing-sm);
  color: var(--primary-color);
}

.error-message {
  color: #d32f2f;
}

.success-message {
  color: #2e7d32;
}


/* Chart Styles */
.chart {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  background: white;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 2px solid var(--primary-color);
  box-shadow: var(--shadow-default);
  width: 100%;
  max-width: 300px;  /* Add a max-width */
  margin: 0 auto; 
  box-sizing: border-box; /* Add this */
}

.chart > div:first-child {
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
  color: var(--primary-color);
}

.word-count {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  //gap: var(--spacing-sm);
  background: var(--light-blue);
  width: 100%;
  box-sizing: border-box;
  padding: 0; /* Remove padding */
  margin: 0; 
}

.word-count-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background: var(--light-blue);
  //border-radius: var(--border-radius-sm);
  transition: background-color var(--transition-speed) var(--transition-timing);
  box-sizing: border-box; /* Add this */
  margin: 2px 0;
  padding: var(--spacing-sm) 20px;
}

.word-count-item:hover {
  background: #e8f0ff;
}

.word-length {
  font-weight: bold;
  color: var(--primary-color);
}

.word-count-number {
  font-family: monospace;
  font-size: 1.1rem;
  color: var(--primary-dark);
}

/* Responsive Design */
@media (max-width: 768px) {

  .parent {
    grid-template-columns: 1fr;
    gap: var(--spacing-sm);
    padding: var(--spacing-sm);
  }

  .mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .board {
    max-width: 300px;
    margin: var(--spacing-sm) auto;
    gap: 4px;
    padding: 4px;
  }

  .col1, .col3 {
    display: none;
  }

  .col2 {
    order: 0;
    width: 100%;
  }

  h1 {
    font-size: 1.5rem;
    text-align: center;
    margin: var(--spacing-sm) 0;
  }

  .score {
    font-size: 1.2rem;
    margin: var(--spacing-sm) auto;
  }

  textarea {
    width: calc(100% - 16px);
    font-size: 16px;
    height: 36px;
    padding: var(--spacing-sm);
    margin: 0 auto;
  }

  .reshuffle-button {
    width: 50%;
    padding: 12px;
    margin: var(--spacing-xs) auto;
  }

  .cubie span {
    font-size: 2rem !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100% !important;
    height: 100% !important;
  }

  .chart {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .word-count {
    width: 100%;
    margin: 0 auto;
  }

  .word-count-item {
    width: 100%;
  }
}

/* Additional Mobile Optimizations */

/* Touch Device Optimizations */
@media (hover: none) {
  .reshuffle-button {
    -webkit-tap-highlight-color: transparent;
    padding: 15px;
  }
}